import React from 'react'
import { Link } from 'react-router-dom'
import './navigationDesktop.scss'

export const NavDesktop = () => {
  return (
    <div className="navigation text-yellow-400">
      <Link to={'/'} className="link">
        Home
      </Link>
      <Link to={'/login'} className="link">
        Login
      </Link>
      <Link to={'/register'} className="link">
        Register
      </Link>
      <Link to={'/category'} className="link">
        Category
      </Link>
    </div>
  )
}
