import React from 'react'
import ReactDOM from 'react-dom/client'
import './index.css'
import App from './App'
import { isMobile, isTablet, isBrowser } from 'react-device-detect'
//import reportWebVitals from './reportWebVitals'

const deviceType = isMobile ? 'mobile' : isTablet ? 'tablet' : 'desktop'

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement)
root.render(
  <React.StrictMode>
    <App deviceType={deviceType} />
  </React.StrictMode>,
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
//reportWebVitals(console.log)
