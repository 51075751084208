import React from 'react'
import { Link } from 'react-router-dom'

export const NavMobile = () => {
  return (
    <div>
      <Link to={'/'}>Home</Link>
      <Link to={'/login'}>Login</Link>
      <Link to={'/register'}>Register</Link>
      <Link to={'/category'}>Category</Link>
    </div>
  )
}
