import { Route, BrowserRouter as Router, Routes } from 'react-router-dom'
import '../src/styles/appStyle.scss'
import { CategoryDesktop } from './components/desktop/CategoryDesktop'
import { HomeDesktop } from './components/desktop/HomeDesktop'
import { LoginDesktop } from './components/desktop/LoginDesktop'
import { NavDesktop } from './components/desktop/NavDesktop'
import { RegisterDesktop } from './components/desktop/RegisterDesktop'
import { CategoryMobile } from './components/mobile/CategoryMobile'
import { HomeMobile } from './components/mobile/HomeMobile'
import { LoginMobile } from './components/mobile/LoginMobile'
import { NavMobile } from './components/mobile/NavMobile'
import { RegisterMobile } from './components/mobile/RegisterMobile'
import { CategoryTablet } from './components/tablet/CategoryTablet'
import { HomeTablet } from './components/tablet/HomeTablet'
import { LoginTablet } from './components/tablet/LoginTablet'
import { NavTablet } from './components/tablet/NavTablet'
import { RegisterTablet } from './components/tablet/RegisterTablet'

type ComponentPropsType = {
  deviceType: 'mobile' | 'tablet' | 'desktop'
}

function App({ deviceType }: ComponentPropsType): JSX.Element {
  const buildNav = (): JSX.Element => {
    switch (deviceType) {
      case 'mobile':
        return <NavMobile></NavMobile>
      case 'tablet':
        return <NavTablet></NavTablet>
      case 'desktop':
      default:
        return <NavDesktop></NavDesktop>
    }
  }

  const buildLayout = (): JSX.Element => {
    switch (deviceType) {
      case 'mobile':
        return (
          <Routes>
            <Route path="/" element={<HomeMobile />} />
            <Route path="/login" element={<LoginMobile />} />
            <Route path="/register" element={<RegisterMobile />} />
            <Route path="/category" element={<CategoryMobile />} />
          </Routes>
        )
      case 'tablet':
        return (
          <Routes>
            <Route path="/" element={<HomeTablet />} />
            <Route path="/login" element={<LoginTablet />} />
            <Route path="/register" element={<RegisterTablet />} />
            <Route path="/category" element={<CategoryTablet />} />
          </Routes>
        )
      case 'desktop':
      default:
        return (
          <Routes>
            <Route path="/" element={<HomeDesktop />} />
            <Route path="/login" element={<LoginDesktop />} />
            <Route path="/register" element={<RegisterDesktop />} />
            <Route path="/category" element={<CategoryDesktop />} />
          </Routes>
        )
    }
  }

  return (
    <Router>
      <div>
        <div>{buildNav()}</div>
        <div>{buildLayout()}</div>
      </div>
      {/* <div>Footer</div> */}
    </Router>
  )
}

export default App
