import { useState } from 'react'

export const RegisterMobile = () => {
  const [username, setUsername] = useState('')
  const [password, setPassword] = useState('')
  const [name, setName] = useState('')
  const [surname, setSurname] = useState('')
  const [email, setEmail] = useState('')
  const [via, setVia] = useState('')
  const [cap, setCap] = useState('')
  const [province, setProvince] = useState('')

  const API_ENDPOINT = 'https://greedynun.com/auth/register'

  const handleSubmit = (event: any) => {
    event.preventDefault()

    const fetchData = async () => {
      try {
        const response = await fetch(API_ENDPOINT, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            username,
            password,
          }),
        })
        const json = await response.json()
        console.log(json)
      } catch (err) {
        console.log(err)
      }
    }
    fetchData()
    console.log(`Username: ${username} Password: ${password}`)
  }

  return (
    <form onSubmit={handleSubmit} className="bg-white p-6 rounded-lg shadow-md">
      <div className="mb-4">
        <label
          htmlFor="username"
          className="block text-gray-700 font-medium mb-2"
        >
          Username:
        </label>
        <input
          type="text"
          id="username"
          value={username}
          onChange={(event) => setUsername(event.target.value)}
          className="border border-gray-400 p-2 w-full"
        />
      </div>
      <div className="mb-4">
        <label htmlFor="name" className="block text-gray-700 font-medium mb-2">
          Name:
        </label>
        <input
          type="text"
          id="name"
          value={name}
          onChange={(event) => setName(event.target.value)}
          className="border border-gray-400 p-2 w-full"
        />
      </div>
      <div className="mb-4">
        <label
          htmlFor="surname"
          className="block text-gray-700 font-medium mb-2"
        >
          Surname:
        </label>
        <input
          type="text"
          id="surname"
          value={surname}
          onChange={(event) => setSurname(event.target.value)}
          className="border border-gray-400 p-2 w-full"
        />
      </div>
      <div className="mb-4">
        <label htmlFor="email" className="block text-gray-700 font-medium mb-2">
          Email:
        </label>
        <input
          type="email"
          id="email"
          value={email}
          onChange={(event) => setEmail(event.target.value)}
          className="border border-gray-400 p-2 w-full"
        />
      </div>
      <div className="mb-4">
        <label htmlFor="via" className="block text-gray-700 font-medium mb-2">
          Via:
        </label>
        <input
          type="text"
          id="via"
          value={via}
          onChange={(event) => setVia(event.target.value)}
          className="border border-gray-400 p-2 w-full"
        />
        <label htmlFor="cap" className="block text-gray-700 font-medium mb-2">
          CAP:
        </label>
        <input
          type="text"
          id="cap"
          value={cap}
          onChange={(event) => setCap(event.target.value)}
          className="border border-gray-400 p-2 w-full"
        />
        <label
          htmlFor="province"
          className="block text-gray-700 font-medium mb-2"
        >
          Province:
        </label>
        <input
          type="text"
          id="province"
          value={province}
          onChange={(event) => setProvince(event.target.value)}
          className="border border-gray-400 p-2 w-full"
        />
      </div>
      <div className="mb-4">
        <label
          htmlFor="password"
          className="block text-gray-700 font-medium mb-2"
        >
          Password:
        </label>
        <input
          type="password"
          id="password"
          value={password}
          onChange={(event) => setPassword(event.target.value)}
          className="border border-gray-400 p-2 w-full"
        />
      </div>
      <button
        type="submit"
        className="bg-indigo-500 text-white py-2 px-4 rounded hover:bg-indigo-600"
      >
        Register
      </button>
    </form>
  )
}
