import { useEffect, useState } from 'react'
import './loginDesktop.scss'
import { useNavigate } from 'react-router-dom'

export const LoginDesktop = () => {
  const navigate = useNavigate()
  const [username, setUsername] = useState('')
  const [password, setPassword] = useState('')
  const [isLoggedIn, setIsLoggedIn] = useState(false)

  const API_ENDPOINT = 'https://greedynun.com/auth/login'

  useEffect(() => {
    if (isLoggedIn) {
      navigate('/category')
    }
  }, [isLoggedIn, navigate])

  const handleSubmit = (event: any) => {
    event.preventDefault()

    const fetchData = async () => {
      try {
        const response = await fetch(API_ENDPOINT, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            username,
            password,
          }),
        })
        const json = await response.json()
        console.log(json)
        if (response.status === 201) {
          setIsLoggedIn(true)
        }
      } catch (err) {
        console.log(err)
      }
    }
    fetchData()
    console.log(`Username: ${username} Password: ${password}`)
  }

  const buildForm = (): JSX.Element => {
    return (
      <form
        onSubmit={handleSubmit}
        className="bg-black bg-opacity-70 p-6 rounded-lg shadow-md"
      >
        <div className="mb-4">
          <label
            htmlFor="username"
            className="block text-yellow-400 font-medium mb-2"
          >
            Username:
          </label>
          <input
            type="text"
            id="username"
            value={username}
            onChange={(event) => setUsername(event.target.value)}
            className="border border-gray-400 p-2 w-full text-yellow-400 bg-transparent"
          />
        </div>
        <div className="mb-4">
          <label
            htmlFor="password"
            className="block text-yellow-400 font-medium mb-2"
          >
            Password:
          </label>
          <input
            type="password"
            id="password"
            value={password}
            onChange={(event) => setPassword(event.target.value)}
            className="border border-gray-400 p-2 w-full text-yellow-400 bg-transparent"
          />
        </div>
        <button
          type="submit"
          className="bg-yellow-400 text-black py-2 px-4 rounded hover:bg-yellow-500"
        >
          Login
        </button>
      </form>
    )
  }

  return (
    <div className="loginDesktopContainer">
      <div className="formContainer">{buildForm()}</div>
    </div>
  )
}
