import { useState } from 'react'

export const LoginTablet = () => {
  const [username, setUsername] = useState('')
  const [password, setPassword] = useState('')

  const API_ENDPOINT = 'https://greedynun.com/auth/login'

  const handleSubmit = (event: any) => {
    event.preventDefault()

    const fetchData = async () => {
      try {
        const response = await fetch(API_ENDPOINT, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            username,
            password,
          }),
        })
        const json = await response.json()
        console.log(json)
      } catch (err) {
        console.log(err)
      }
    }
    fetchData()
    console.log(`Username: ${username} Password: ${password}`)
  }

  return (
    <form onSubmit={handleSubmit} className="bg-white p-6 rounded-lg shadow-md">
      <div className="mb-4">
        <label
          htmlFor="username"
          className="block text-gray-700 font-medium mb-2"
        >
          Username:
        </label>
        <input
          type="text"
          id="username"
          value={username}
          onChange={(event) => setUsername(event.target.value)}
          className="border border-gray-400 p-2 w-full"
        />
      </div>
      <div className="mb-4">
        <label
          htmlFor="password"
          className="block text-gray-700 font-medium mb-2"
        >
          Password:
        </label>
        <input
          type="password"
          id="password"
          value={password}
          onChange={(event) => setPassword(event.target.value)}
          className="border border-gray-400 p-2 w-full"
        />
      </div>
      <button
        type="submit"
        className="bg-indigo-500 text-white py-2 px-4 rounded hover:bg-indigo-600"
      >
        Login
      </button>
    </form>
  )
}
