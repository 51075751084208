import { useState } from 'react'
import './registerDesktop.scss'

export const RegisterDesktop = () => {
  const [username, setUsername] = useState('')
  const [password, setPassword] = useState('')
  const [name, setName] = useState('')
  const [surname, setSurname] = useState('')
  const [email, setEmail] = useState('')
  const [address, setAddress] = useState({
    via: '',
    cap: '',
    province: '',
  })

  const API_ENDPOINT = 'https://greedynun.com/auth/register'

  const handleSubmit = (event: any) => {
    event.preventDefault()

    const fetchData = async () => {
      try {
        const response = await fetch(API_ENDPOINT, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            username,
            password,
            name,
            surname,
            email,
            address,
          }),
        })
        const json = await response.json()
        console.log(json)
      } catch (err) {
        console.log(err)
      }
    }

    fetchData()
  }

  const buildForm = (): JSX.Element => {
    return (
      <form
        onSubmit={handleSubmit}
        className="bg-black bg-opacity-70 p-4 rounded-lg shadow-md"
      >
        <div className="mb-4 flex justify-between">
          <div>
            <label
              htmlFor="name"
              className="block text-yellow-400 font-medium mb-2"
            >
              Name:
            </label>
            <input
              type="text"
              id="name"
              value={name}
              onChange={(event) => setName(event.target.value)}
              className="border border-gray-400 p-1 w-full text-yellow-400 bg-transparent"
            />
          </div>
          <div>
            <label
              htmlFor="surname"
              className="block text-yellow-400 font-medium mb-2"
            >
              Surname:
            </label>
            <input
              type="text"
              id="surname"
              value={surname}
              onChange={(event) => setSurname(event.target.value)}
              className="border border-gray-400 p-1 w-full text-yellow-400 bg-transparent"
            />
          </div>
        </div>
        <div className="mb-4">
          <label
            htmlFor="email"
            className="block text-yellow-400 font-medium mb-2"
          >
            Email:
          </label>
          <input
            type="email"
            id="email"
            value={email}
            onChange={(event) => {
              setEmail(event.target.value)
              setUsername(event.target.value)
            }}
            className="border border-gray-400 p-1 w-full text-yellow-400 bg-transparent"
          />
        </div>
        <div className="mb-4">
          <label
            htmlFor="via"
            className="block text-yellow-400 font-medium mb-2"
          >
            Via:
          </label>
          <input
            type="text"
            id="via"
            value={address.via}
            onChange={(event) =>
              setAddress({
                ...address,
                via: event.target.value,
              })
            }
            className="border border-gray-400 p-1 w-full text-yellow-400 bg-transparent"
          />
        </div>
        <div className="mb-4 flex justify-between">
          <div>
            <label
              htmlFor="cap"
              className="block text-yellow-400 font-medium mb-2"
            >
              CAP:
            </label>
            <input
              type="text"
              id="cap"
              value={address.cap}
              onChange={(event) =>
                setAddress({
                  ...address,
                  cap: event.target.value,
                })
              }
              className="border border-gray-400 p-1 w-full text-yellow-400 bg-transparent"
            />
          </div>
          <div>
            <label
              htmlFor="province"
              className="block text-yellow-400 font-medium mb-2"
            >
              Province:
            </label>
            <input
              type="text"
              id="province"
              value={address.province}
              onChange={(event) =>
                setAddress({
                  ...address,
                  province: event.target.value,
                })
              }
              className="border border-gray-400 p-1 w-full text-yellow-400 bg-transparent"
            />
          </div>
        </div>
        <div className="mb-4">
          <label
            htmlFor="password"
            className="block text-yellow-400 font-medium mb-2"
          >
            Password:
          </label>
          <input
            type="password"
            id="password"
            value={password}
            onChange={(event) => setPassword(event.target.value)}
            className="border border-gray-400 p-1 w-full text-yellow-400 bg-transparent"
          />
        </div>
        <button
          type="submit"
          className="bg-yellow-400 text-black py-2 px-4 rounded hover:bg-yellow-500"
        >
          Register
        </button>
      </form>
    )
  }

  return (
    <div className="registerDesktopContainer">
      <div className="formContainer_register">{buildForm()}</div>
    </div>
  )
}
